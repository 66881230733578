@use "../abstracts/variables";

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border: 2px solid #707070;
}

.slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 1px;
    bottom: 1.5px;
    border: 2px solid white;
    background-color: #707070;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

input:checked + .slider {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background-color: variables.palette("primary");
    border-color: variables.palette("primary");
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
    background-color: variables.palette("primary");
    border: 2px solid white;
    height: 19px;
    width: 19px;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
