@use "../abstracts/mixins";
@use "../abstracts/variables";

// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

body {
  font-size: 12px;
  overflow-y: hidden;

  background: rgba(0, 0, 0, 0.02);
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: variables.$brand-color;
  text-decoration: none;
  cursor: pointer;

  @include mixins.on-event {
    color: variables.$text-color;
    text-decoration: underline;
  }
}
