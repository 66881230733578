@use "../abstracts/variables";

// -----------------------------------------------------------------------------
// This file contains all styles related to the forms.
// -----------------------------------------------------------------------------

.form {
  .text-input {
    margin-top: -12px;

    &:focus {
      outline-color: transparent;
      outline-style: none;
    }

    &.invalid {
      border-left: 1px solid black;
    }
  }

  .text-input-description {
    color: variables.$brand-color-medium-grey;
    font-style: italic;
    font-size: 80%;
    height: 0;
    margin-top: -13px;

    &.invalid {
      color: variables.palette("warn");
    }
  }

  .discrete-text-input {
    margin-top: -12px;

    &:focus {
      outline-color: transparent !important;
      outline-style: none !important;
    }

    &.invalid {
      border-left: none;
    }
  }
}

.radiobutton:hover {
  box-shadow: 0px 0px 1px 1px variables.palette("primary");
}
