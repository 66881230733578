@use "sass:map";
// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Open Sans", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Monaco", monospace !default;

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: #28A0A6 !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

$oceana: linear-gradient(172.25deg, #35e8d0 5.99%, #2edfe6 95.64%);

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "small": 320px,
  "medium": 768px,
  "large": 1024px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/assets/" !default;

$brand-background: #dfe3e6;
$brand-foreground: #fff;

$brand-color-black: #171a19;
$brand-color-light-grey: #f5f7f7;
$brand-color-medium-grey: #cacccc;
$brand-color-dark-grey: #646666;
$brand-font-stack: "Poppins", "Open Sans", sans-serif;
$brand-border-color: #cacccc;

$palettes: (
  primary: (
    lightest: hsl(175, 47%, 97%),
    lighter: hsl(175, 47%, 61%),
    light: hsl(175, 47%, 51%),
    base: hsl(175, 47%, 49%),
    dark: hsl(175, 47%, 35%),
    darker: hsl(175, 47%, 31%),
    darkest: hsl(175, 47%, 26%),
  ),
  secondary: (
    lightest: hsl(232, 44%, 61%),
    lighter: hsl(232, 44%, 52%),
    light: hsl(232, 44%, 45%),
    base: hsl(232, 44%, 38%),
    dark: hsl(232, 44%, 30%),
    darker: hsl(232, 44%, 22%),
    darkest: hsl(232, 44%, 16%),
  ),
  warn: (
    lightest: hsl(1, 94%, 88%),
    lighter: hsl(1, 94%, 84%),
    light: hsl(1, 94%, 78%),
    base: hsl(1, 94%, 67%),
    dark: hsl(1, 94%, 52%),
    darker: hsl(1, 94%, 46%),
    darkest: hsl(1, 94%, 42%),
  ),
);

@function palette($palette, $tone: "base") {
  @return map.get(map.get($palettes, $palette), $tone);
}

$shadow: (
  "s": (
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24),
  ),
  "m": (
    0 3px 6px rgba(0, 0, 0, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.13),
  ),
  "l": (
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23),
  ),
  "xl": (
    0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22),
  ),
  "xxl": (
    0 19px 38px rgba(0, 0, 0, 0.3),
    0 15px 12px rgba(0, 0, 0, 0.22),
  ),
);
