@use "../abstracts/variables";

.svg-streams {
  top: 0;
  z-index: 4;
  position: absolute;
  left: 0;
  cursor: default;
  pointer-events: none;
}

.node-connection {
  pointer-events: all;
  z-index: 2;
}

.line {
  stroke: rgba(0, 0, 0, 0.1);

  &.calculated {
    &.energy-connection {
      stroke: #fabe02;
    }

    &.ingredient-connection {
      stroke: rgb(181, 243, 237);
    }
  }
}

.connecting-view {
  z-index: 13;

  .new-ingredient-connection {
    stroke: variables.palette("primary", "darker");
  }

  .new-energy-connection {
    stroke: rgb(112, 117, 227);
  }
}
