@use "../abstracts/variables";

.svg-mouse {
  position: absolute;
  top: 0;
  z-index: 40;
}

.reference-cell-occupied {
  .mdi {
    margin: auto;
    color: #fff;
  }
}

.reference-row {
  z-index: 3;
  position: relative;
}

.reference-cell {
  .icon {
    margin: auto;
    color: variables.$brand-color-medium-grey;
  }

  transition: font-size 0.07s linear;

  &.drop-target {
    border: 2px dashed rgba(100, 100, 230, 0.55);
    background-color: rgba(100, 100, 230, 0.03);
    border-radius: 15px;
    transition: background-color 0.6s cubic-bezier(0.24, 1.33, 0.59, 0.89);
  }
}

.reference-cell:hover {
  background-color: #f4f4f4;
  cursor: copy;
  border-radius: 15px;
  background-color: rgba(100, 100, 230, 0.03);
  transition: background-color 0.6s cubic-bezier(0.24, 1.33, 0.59, 0.89);
}

#toolbar {
  z-index: 42;
  font-weight: 800;
}

#deleteProcessButton div {
  margin-left: 25px;
  margin-right: 25px;
}

#clickToStartButton div {
  margin-left: 25px;
  margin-right: 25px;
}

#node-area-background-cover {
  position: absolute;
  z-index: 1;
}
