// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

#startpage {
    
    .sticky {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        z-index: 1;
    }

    // See https://github.com/mdgriffith/design-discussion-elm-ui-2/issues/10
    .white-space-nowrap > div {
        white-space: nowrap;
    }
}
