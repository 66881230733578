@use "../abstracts/mixins";
@use "../abstracts/variables";

// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.category-btn {
  background: variables.$light-grey;
  border-radius: 100%;
  font-size: 11px;
  @include mixins.shadow("m");

  &:hover {
    @include mixins.shadow("l");
  }
}

.text-button {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  &.active {
    font-weight: bold;
  }
}

.text-button:hover {
  text-decoration: underline;
}

.discreteIconButton:hover {
  fill: variables.palette("primary");
}

.discreteIconButtonWithLabel:hover {
  fill: variables.palette("primary");
  color: variables.palette("primary");
}

.raised-primary-button {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 600;
  border: none;
  border-radius: 30px;
  background-color: variables.palette("primary");
  color: variables.$brand-color-light-grey;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  z-index: 2;
  @include mixins.shadow("s");

  span,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include mixins.shadow("m");
    transition: opacity 0.3s ease-out;
    z-index: 1;
  }

  &:hover {
    background-color: variables.palette("primary", "dark");
    transform: translateY(-1px);

    &::after {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.3;
    box-shadow: none;
  }
}

.raised-wide-button {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 600;
  border: none;
  border-radius: 30px;
  background-color: variables.palette("primary");
  color: variables.$brand-color-light-grey;
  padding: 10px 45px;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.6s cubic-bezier(0.24, 1.33, 0.59, 0.89);
  z-index: 2;

  span,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include mixins.shadow("m");
    transition: opacity 0.3s ease-out;
    z-index: 1;
  }

  &:hover {
    background-color: variables.palette("primary", "dark");
    transform: translateY(-1px);

    &::after {
      opacity: 1;
    }
  }
}

.stroked-icon-button {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 700;
  border: none; /* Remove borders */
  color: variables.$brand-color-black;
  border: solid variables.$brand-color-black 2px;
  border-radius: 30px;
  padding: 5px 15px; /* Some padding */
  font-size: 14px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  transition: transform 0.6s cubic-bezier(0.24, 1.33, 0.59, 0.89);
  z-index: 2;
  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include mixins.shadow("m");
    transition: opacity 0.3s ease-out;
    z-index: 1;
  }

  &:hover {
    transform: translateY(-1px);

    &::after {
      opacity: 1;
    }
  }
}

.stroked-icon-button-inactive {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 700;
  border: none;
  color: variables.$brand-color-black;
  border: dashed variables.$brand-color-black 2px;
  border-radius: 30px;
  padding: 5px 15px;
  font-size: 14px;
  font-style: italic;
  cursor: normal;
  z-index: 2;
  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

.filter-button {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 500;
  border: none;
  background: none;
  color: variables.$brand-color-light-grey;

  border-radius: 30px;
  padding: 5px 15px;
  font-size: 14px;
  transition: border-color 0.6s cubic-bezier(0.24, 1.33, 0.59, 0.89);
  cursor: pointer;
  border: solid rgba(0, 0, 0, 0) 1px;

  &.selected {
    cursor: default;
    border-color: variables.$brand-color-light-grey;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    border-color: variables.$brand-color-light-grey;
  }
}
