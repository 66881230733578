@use "../abstracts/variables";

.modal1 {
  background: white;
  -webkit-box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid variables.$brand-border-color;
  z-index: 100;
}

.modal2 {
  position: fixed;
  -webkit-box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  background: white;
  max-height: 90vh;
  width: 750px;
  height: auto;
  border-radius: 15px;
  white-space: normal;
  z-index: 1000;
  margin-top: 5vh;
  margin-bottom: auto;
  overflow-y: visible;
}

.modal2 > div {
  overflow-y: auto;
  max-height: 90vh;
}

.modal3 {
  position: fixed;
  -webkit-box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  background: white;
  max-height: 90vh;
  width: 600px;
  height: auto;
  border-radius: 0px;
  white-space: normal;
  z-index: 1000;
  margin-top: 5vh;
  margin-bottom: auto;
  overflow-y: auto;

  .text-input-description {
    font-size: 10px;
  }
}

.modal-crunch {
  -webkit-box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  background: white;
  position: fixed;
  left: calc(50vw - 250px);
  top: calc(50vh - 150px);
  z-index: 1000;
  width: 500px;
  height: 300px;
  border-radius: 15px;
  overflow-y: hidden;
  white-space: normal;

  img {
    width: 100px;
    margin-left: calc(50% - 50px);
    padding-top: 20px;
  }
}

.modal-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center; /* horizontal */
}

.modal-cancelArea {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: none;
  position: fixed;
}

.modal-close-button {
  position: fixed;
  left: calc(100% - 70px);
  top: 0px;
  z-index: 12;
  cursor: pointer;
  background-color: none;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  color: grey;

  span {
    margin-left: 35px;
    display: inline-block;
    margin-top: 5px;
    font-size: 30px;
  }

  &:hover {
    color: black;
  }
}
