/*
  Icon Font: carbon-data-icons
*/

@font-face {
  font-family: "carbon-data-icons";
  src: url("../fonts/carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.eot");
  src: url("../fonts/carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.eot?#iefix") format("embedded-opentype"),
       url("../fonts/carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.woff2") format("woff2"),
       url("../fonts/carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.woff") format("woff"),
       url("../fonts/carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.ttf") format("truetype"),
       url("../fonts/carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.svg#carbon-data-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "carbon-data-icons";
    src: url("../fonts/carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.svg#carbon-data-icons") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-flash:before,
.icon-gas-station:before,
.icon-home-variant:before,
.icon-ingredient-referral:before,
.icon-leaf:before,
.icon-link-variant:before,
.icon-link-variant-off:before,
.icon-package-variant-closed:before,
.icon-plus:before,
.icon-refinement:before,
.icon-truck:before,
.icon-truck-moving-solid:before {
  display: inline-block;
  font-family: "carbon-data-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-flash:before { content: "\f100"; }
.icon-gas-station:before { content: "\f101"; }
.icon-home-variant:before { content: "\f102"; }
.icon-ingredient-referral:before { content: "\f103"; }
.icon-leaf:before { content: "\f104"; }
.icon-link-variant:before { content: "\f105"; }
.icon-link-variant-off:before { content: "\f106"; }
.icon-package-variant-closed:before { content: "\f107"; }
.icon-plus:before { content: "\f108"; }
.icon-refinement:before { content: "\f10b"; }
.icon-truck:before { content: "\f109"; }
.icon-truck-moving-solid:before { content: "\f10a"; }
