@use "../abstracts/variables";

// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

.cc-search-input {
  background-color: white;
  border-radius: 3px;

  input[type="text"] {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    display: inline-block;
    vertical-align: middle;
    height: 35px;
    width: 200px;
    box-sizing: border-box;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    padding: 10px;
  }

  .cc-search-input-icon {
    display: inline-block;
    vertical-align: middle;
    height: 28px;
  }

  &:focus-within {
    box-shadow: 0 0 0 2px variables.palette("primary");
  }
}
