@charset 'UTF-8';

// 1. Configuration and helpers
@use "abstracts/variables";
@use "abstracts/functions";
@use "abstracts/mixins";

// 2. Vendors
@use "vendors/normalize";

// 3. Base stuff
@use "base/base";
@use "base/fonts";
@use "base/typography";
@use "base/helpers";
@use "base/icons";

// 4. Layout-related sections
@use "layout/header";
@use "layout/footer";

// 5. Components
@use "components/button";
@use "components/modal";
@use "components/node";
@use "components/nodes-area";
@use "components/summary";
@use "components/form";
@use "components/node-connection";
@use "components/table";
@use "components/toggle-switch";
@use "components/_radio-buttons";
@use "components/loading-spinner";

// 6. Page-specific styles
@use "pages/home";

// 7. Themes
@use "themes/default";

.monospaced-characters {
  /* stylelint-disable */
  // !important is required due to limitations of elmUI
  // Browser workaround see https://stackoverflow.com/questions/38781089/font-family-monospace-monospace
  font-family: "B612 Mono" !important;
  /* stylelint-enable */
}

/* latin */
@font-face {
  font-family: "B612 Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("B612 Mono Regular"), local("B612Mono-Regular"),
    url("../b612mono.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.loading-rows {
  opacity: 0;
  animation: "loading-row-animation" 1s linear 0s infinite;
}

@keyframes loading-row-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.text-input-with-suffix:focus-within {
  box-shadow: 0 0 4px 0 variables.palette("primary");
}

.opacity1 {
  opacity: 1 !important;
}

fieldset {
  padding: 0;
}
