@use "../abstracts/variables";

.summary {
  height: calc(100vh - 80px);
  position: fixed !important;
  right: 1.3vh;
  top: 52px;
  box-shadow: 2px 4px 7px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  border: 1px solid variables.$brand-border-color;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  user-select: none;

  &.can-connect {
    border: none;

    &:hover {
      cursor: pointer;
      box-shadow: 2px 4px 7px 4px variables.palette("primary", "light");
    }
  }

  &.disabled {
    border: none;
  }
}

.status-message {
  background: rgba(0, 255, 0, 1);
  border-radius: 20px;
}
