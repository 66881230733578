// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  src: local("Poppins Thin Italic"),
 local("Poppins-ThinItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiAyp8kv8JHgFVrJJLmE0tDMPKzSQ.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  src: local("Poppins Thin Italic"),
 local("Poppins-ThinItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiAyp8kv8JHgFVrJJLmE0tMMPKzSQ.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  src: local("Poppins Thin Italic"),
 local("Poppins-ThinItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiAyp8kv8JHgFVrJJLmE0tCMPI.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  src: local("Poppins ExtraLight Italic"),
 local("Poppins-ExtraLightItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmv1pVFteOcEg.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  src: local("Poppins ExtraLight Italic"),
 local("Poppins-ExtraLightItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmv1pVGdeOcEg.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  src: local("Poppins ExtraLight Italic"),
 local("Poppins-ExtraLightItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmv1pVF9eO.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: local("Poppins Light Italic"),
 local("Poppins-LightItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm21lVFteOcEg.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: local("Poppins Light Italic"),
 local("Poppins-LightItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm21lVGdeOcEg.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: local("Poppins Light Italic"),
 local("Poppins-LightItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm21lVF9eO.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: local("Poppins Italic"),
 local("Poppins-Italic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrJJLucXtAKPY.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: local("Poppins Italic"),
 local("Poppins-Italic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrJJLufntAKPY.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: local("Poppins Italic"),
 local("Poppins-Italic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrJJLucHtA.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: local("Poppins Medium Italic"),
 local("Poppins-MediumItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmg1hVFteOcEg.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: local("Poppins Medium Italic"),
 local("Poppins-MediumItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmg1hVGdeOcEg.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: local("Poppins Medium Italic"),
 local("Poppins-MediumItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmg1hVF9eO.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: local("Poppins SemiBold Italic"),
 local("Poppins-SemiBoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmr19VFteOcEg.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: local("Poppins SemiBold Italic"),
 local("Poppins-SemiBoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmr19VGdeOcEg.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: local("Poppins SemiBold Italic"),
 local("Poppins-SemiBoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmr19VF9eO.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: local("Poppins Bold Italic"),
 local("Poppins-BoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmy15VFteOcEg.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: local("Poppins Bold Italic"),
 local("Poppins-BoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmy15VGdeOcEg.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: local("Poppins Bold Italic"),
 local("Poppins-BoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmy15VF9eO.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: local("Poppins ExtraBold Italic"),
 local("Poppins-ExtraBoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm111VFteOcEg.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: local("Poppins ExtraBold Italic"),
 local("Poppins-ExtraBoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm111VGdeOcEg.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: local("Poppins ExtraBold Italic"),
 local("Poppins-ExtraBoldItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm111VF9eO.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  src: local("Poppins Black Italic"),
 local("Poppins-BlackItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm81xVFteOcEg.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  src: local("Poppins Black Italic"),
 local("Poppins-BlackItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm81xVGdeOcEg.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  src: local("Poppins Black Italic"),
 local("Poppins-BlackItalic"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm81xVF9eO.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: local("Poppins Thin"),
 local("Poppins-Thin"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrLPTucXtAKPY.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: local("Poppins Thin"),
 local("Poppins-Thin"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrLPTufntAKPY.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: local("Poppins Thin"),
 local("Poppins-Thin"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrLPTucHtA.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: local("Poppins ExtraLight"),
 local("Poppins-ExtraLight"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLFj_Z11lFc-K.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: local("Poppins ExtraLight"),
 local("Poppins-ExtraLight"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLFj_Z1JlFc-K.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: local("Poppins ExtraLight"),
 local("Poppins-ExtraLight"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"),
 local("Poppins-Light"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"),
 local("Poppins-Light"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"),
 local("Poppins-Light"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"),
 local("Poppins-Regular"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJbecmNE.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"),
 local("Poppins-Regular"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJnecmNE.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"),
 local("Poppins-Regular"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJfecg.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"),
 local("Poppins-Medium"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"),
 local("Poppins-Medium"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"),
 local("Poppins-Medium"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"),
 local("Poppins-SemiBold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"),
 local("Poppins-SemiBold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"),
 local("Poppins-SemiBold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"),
 local("Poppins-Bold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"),
 local("Poppins-Bold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"),
 local("Poppins-Bold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local("Poppins ExtraBold"),
 local("Poppins-ExtraBold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDD4Z11lFc-K.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local("Poppins ExtraBold"),
 local("Poppins-ExtraBold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local("Poppins ExtraBold"),
 local("Poppins-ExtraBold"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local("Poppins Black"),
 local("Poppins-Black"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLBT5Z11lFc-K.woff2)
      format("woff2");
  unicode-range: U+0900-097F,
 U+1CD0-1CF6,
 U+1CF8-1CF9,
 U+200C-200D,
 U+20A8,
    U+20B9,
 U+25CC,
 U+A830-A839,
 U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local("Poppins Black"),
 local("Poppins-Black"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLBT5Z1JlFc-K.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local("Poppins Black"),
 local("Poppins-Black"),
    url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),
 local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWJ0bbck.woff2)
      format("woff2");
  unicode-range: U+0460-052F,
 U+1C80-1C88,
 U+20B4,
 U+2DE0-2DFF,
 U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),
 local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFUZ0bbck.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),
 local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWZ0bbck.woff2)
      format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),
 local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVp0bbck.woff2)
      format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),
 local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWp0bbck.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),
 local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFW50bbck.woff2)
      format("woff2");
  unicode-range: U+0100-024F,
 U+0259,
 U+1E00-1EFF,
 U+2020,
 U+20A0-20AB,
    U+20AD-20CF,
 U+2113,
 U+2C60-2C7F,
 U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"),
 local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0b.woff2)
      format("woff2");
  unicode-range: U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
    U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
 U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
    U+FEFF,
 U+FFFD;
}
