@use "../abstracts/variables";

.loading-spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 3px solid variables.palette("primary", "lighter");
    border-radius: 50%;
    border-top-color: variables.palette("primary", "dark");
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
