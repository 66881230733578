@use "../abstracts/mixins";

.table-row {
    transition: transform 0.6s cubic-bezier(0.24, 1.33, 0.59, 0.89);

    &:not(:first-of-type) .gtin {
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: -1;
        @include mixins.shadow("s");
        transition: opacity 0.3s ease-out;
    }

    .button {
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    .climate-footprint-unit {
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    &:hover {
        transform: translateY(-1px);

        .button {
            opacity: 1;
        }

        .climate-footprint-unit {
            opacity: 1;
        }

        &::after {
            opacity: 1;
        }

        &:not(:first-of-type) .gtin {
            opacity: 1;
        }
    }
}
