@font-face {
  font-family: carbon-data-icons;
  src: url("carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.8526c998.eot");
  src: url("carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.8526c998.eot#iefix") format("embedded-opentype"), url("carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.a1263314.woff2") format("woff2"), url("carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.a9e39401.woff") format("woff"), url("carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.5ee65d46.ttf") format("truetype"), url("carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.f8431c21.svg#carbon-data-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: carbon-data-icons;
    src: url("carbon-data-icons_dab9e1be5ce0d20d4e9e48272f529857.f8431c21.svg#carbon-data-icons") format("svg");
  }
}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon]:before, .icon-flash:before, .icon-gas-station:before, .icon-home-variant:before, .icon-ingredient-referral:before, .icon-leaf:before, .icon-link-variant:before, .icon-link-variant-off:before, .icon-package-variant-closed:before, .icon-plus:before, .icon-refinement:before, .icon-truck:before, .icon-truck-moving-solid:before {
  font-variant: normal;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-rendering: optimizelegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: carbon-data-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.icon-flash:before {
  content: "";
}

.icon-gas-station:before {
  content: "";
}

.icon-home-variant:before {
  content: "";
}

.icon-ingredient-referral:before {
  content: "";
}

.icon-leaf:before {
  content: "";
}

.icon-link-variant:before {
  content: "";
}

.icon-link-variant-off:before {
  content: "";
}

.icon-package-variant-closed:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-refinement:before {
  content: "";
}

.icon-truck:before {
  content: "";
}

.icon-truck-moving-solid:before {
  content: "";
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline-width: 0;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

[hidden] {
  display: none;
}

html {
  box-sizing: border-box;
}

body {
  background: rgba(0, 0, 0, .02);
  font-size: 12px;
  overflow-y: hidden;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  color: #28a0a6;
  cursor: pointer;
  text-decoration: none;
}

a:hover, a:active, a:focus {
  color: #222;
  text-decoration: underline;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 100;
  src: local(Poppins Thin Italic), local(Poppins-ThinItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiAyp8kv8JHgFVrJJLmE0tDMPKzSQ.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 100;
  src: local(Poppins Thin Italic), local(Poppins-ThinItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiAyp8kv8JHgFVrJJLmE0tMMPKzSQ.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 100;
  src: local(Poppins Thin Italic), local(Poppins-ThinItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiAyp8kv8JHgFVrJJLmE0tCMPI.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 200;
  src: local(Poppins ExtraLight Italic), local(Poppins-ExtraLightItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmv1pVFteOcEg.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 200;
  src: local(Poppins ExtraLight Italic), local(Poppins-ExtraLightItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmv1pVGdeOcEg.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 200;
  src: local(Poppins ExtraLight Italic), local(Poppins-ExtraLightItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmv1pVF9eO.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  src: local(Poppins Light Italic), local(Poppins-LightItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm21lVFteOcEg.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  src: local(Poppins Light Italic), local(Poppins-LightItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm21lVGdeOcEg.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  src: local(Poppins Light Italic), local(Poppins-LightItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm21lVF9eO.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  src: local(Poppins Italic), local(Poppins-Italic), url("https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrJJLucXtAKPY.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  src: local(Poppins Italic), local(Poppins-Italic), url("https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrJJLufntAKPY.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  src: local(Poppins Italic), local(Poppins-Italic), url("https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrJJLucHtA.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  src: local(Poppins Medium Italic), local(Poppins-MediumItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmg1hVFteOcEg.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  src: local(Poppins Medium Italic), local(Poppins-MediumItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmg1hVGdeOcEg.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  src: local(Poppins Medium Italic), local(Poppins-MediumItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmg1hVF9eO.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  src: local(Poppins SemiBold Italic), local(Poppins-SemiBoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmr19VFteOcEg.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  src: local(Poppins SemiBold Italic), local(Poppins-SemiBoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmr19VGdeOcEg.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  src: local(Poppins SemiBold Italic), local(Poppins-SemiBoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmr19VF9eO.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  src: local(Poppins Bold Italic), local(Poppins-BoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmy15VFteOcEg.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  src: local(Poppins Bold Italic), local(Poppins-BoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmy15VGdeOcEg.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  src: local(Poppins Bold Italic), local(Poppins-BoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLmy15VF9eO.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  src: local(Poppins ExtraBold Italic), local(Poppins-ExtraBoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm111VFteOcEg.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  src: local(Poppins ExtraBold Italic), local(Poppins-ExtraBoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm111VGdeOcEg.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  src: local(Poppins ExtraBold Italic), local(Poppins-ExtraBoldItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm111VF9eO.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
  src: local(Poppins Black Italic), local(Poppins-BlackItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm81xVFteOcEg.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
  src: local(Poppins Black Italic), local(Poppins-BlackItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm81xVGdeOcEg.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
  src: local(Poppins Black Italic), local(Poppins-BlackItalic), url("https://fonts.gstatic.com/s/poppins/v6/pxiDyp8kv8JHgFVrJJLm81xVF9eO.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 100;
  src: local(Poppins Thin), local(Poppins-Thin), url("https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrLPTucXtAKPY.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 100;
  src: local(Poppins Thin), local(Poppins-Thin), url("https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrLPTufntAKPY.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 100;
  src: local(Poppins Thin), local(Poppins-Thin), url("https://fonts.gstatic.com/s/poppins/v6/pxiGyp8kv8JHgFVrLPTucHtA.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  src: local(Poppins ExtraLight), local(Poppins-ExtraLight), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLFj_Z11lFc-K.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  src: local(Poppins ExtraLight), local(Poppins-ExtraLight), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLFj_Z1JlFc-K.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  src: local(Poppins ExtraLight), local(Poppins-ExtraLight), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: local(Poppins Light), local(Poppins-Light), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: local(Poppins Light), local(Poppins-Light), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: local(Poppins Light), local(Poppins-Light), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: local(Poppins Regular), local(Poppins-Regular), url("https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJbecmNE.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: local(Poppins Regular), local(Poppins-Regular), url("https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJnecmNE.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: local(Poppins Regular), local(Poppins-Regular), url("https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJfecg.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: local(Poppins Medium), local(Poppins-Medium), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: local(Poppins Medium), local(Poppins-Medium), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: local(Poppins Medium), local(Poppins-Medium), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: local(Poppins SemiBold), local(Poppins-SemiBold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: local(Poppins SemiBold), local(Poppins-SemiBold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: local(Poppins SemiBold), local(Poppins-SemiBold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: local(Poppins Bold), local(Poppins-Bold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: local(Poppins Bold), local(Poppins-Bold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: local(Poppins Bold), local(Poppins-Bold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: local(Poppins ExtraBold), local(Poppins-ExtraBold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDD4Z11lFc-K.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: local(Poppins ExtraBold), local(Poppins-ExtraBold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: local(Poppins ExtraBold), local(Poppins-ExtraBold), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  src: local(Poppins Black), local(Poppins-Black), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLBT5Z11lFc-K.woff2") format("woff2");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  src: local(Poppins Black), local(Poppins-Black), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLBT5Z1JlFc-K.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  src: local(Poppins Black), local(Poppins-Black), url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(Open Sans Regular), local(OpenSans-Regular), url("https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWJ0bbck.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(Open Sans Regular), local(OpenSans-Regular), url("https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFUZ0bbck.woff2") format("woff2");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(Open Sans Regular), local(OpenSans-Regular), url("https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWZ0bbck.woff2") format("woff2");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(Open Sans Regular), local(OpenSans-Regular), url("https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVp0bbck.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(Open Sans Regular), local(OpenSans-Regular), url("https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWp0bbck.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(Open Sans Regular), local(OpenSans-Regular), url("https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFW50bbck.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(Open Sans Regular), local(OpenSans-Regular), url("https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0b.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  color: #222;
  font: 125% / 1.4 Open Sans, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.container {
  max-width: 1180px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.discreteIconButton:hover {
  fill: #42b8ae;
}

.raised-wide-button {
  color: #f5f7f7;
  cursor: pointer;
  z-index: 2;
  background-color: #42b8ae;
  border: none;
  border-radius: 30px;
  padding: 10px 45px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  transition: transform .6s cubic-bezier(.24, 1.33, .59, .89);
}

.raised-wide-button span, .raised-wide-button:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  border-radius: 30px;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .1), 0 3px 6px rgba(0, 0, 0, .13);
}

.raised-wide-button:hover {
  background-color: #2f837c;
  transform: translateY(-1px);
}

.raised-wide-button:hover:after {
  opacity: 1;
}

.stroked-icon-button {
  color: #171a19;
  cursor: pointer;
  z-index: 2;
  border: 2px solid #171a19;
  border-radius: 30px;
  padding: 5px 15px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 700;
  transition: transform .6s cubic-bezier(.24, 1.33, .59, .89);
}

.stroked-icon-button span, .stroked-icon-button svg {
  vertical-align: middle;
  display: inline-block;
}

.stroked-icon-button:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  border-radius: 30px;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .1), 0 3px 6px rgba(0, 0, 0, .13);
}

.stroked-icon-button:hover {
  transform: translateY(-1px);
}

.stroked-icon-button:hover:after {
  opacity: 1;
}

.filter-button {
  color: #f5f7f7;
  cursor: pointer;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 30px;
  padding: 5px 15px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  transition: border-color .6s cubic-bezier(.24, 1.33, .59, .89);
}

.filter-button.selected {
  cursor: default;
  border-color: #f5f7f7;
}

.filter-button span {
  vertical-align: middle;
  display: inline-block;
}

.filter-button:hover {
  border-color: #f5f7f7;
}

.modal2 {
  max-height: 90vh;
  width: 750px;
  height: auto;
  white-space: normal;
  z-index: 1000;
  background: #fff;
  border-radius: 15px;
  margin-top: 5vh;
  margin-bottom: auto;
  position: fixed;
  overflow-y: visible;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, .25);
}

.modal2 > div {
  max-height: 90vh;
  overflow-y: auto;
}

.modal3 {
  max-height: 90vh;
  width: 600px;
  height: auto;
  white-space: normal;
  z-index: 1000;
  background: #fff;
  border-radius: 0;
  margin-top: 5vh;
  margin-bottom: auto;
  position: fixed;
  overflow-y: auto;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, .25);
}

.modal3 .text-input-description {
  font-size: 10px;
}

.modal-crunch {
  z-index: 1000;
  width: 500px;
  height: 300px;
  white-space: normal;
  background: #fff;
  border-radius: 15px;
  position: fixed;
  top: calc(50vh - 150px);
  left: calc(50vw - 250px);
  overflow-y: hidden;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, .25);
}

.modal-crunch img {
  width: 100px;
  margin-left: calc(50% - 50px);
  padding-top: 20px;
}

.modal-backdrop {
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4);
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-cancelArea {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: none;
  position: fixed;
}

.node-box {
  fill: #fff;
}

#cover {
  height: 100%;
  z-index: 10;
  cursor: no-drop;
  position: absolute;
  top: 0;
}

#cover div {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  position: relative;
}

.node {
  z-index: 7;
}

.node .disconnector {
  visibility: hidden;
}

.node:hover .node-box {
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}

.node:hover .disconnector {
  visibility: visible;
}

.node.connect-target {
  z-index: 10;
}

.node.connect-target .connector {
  visibility: hidden;
}

.node.connect-target:hover .node-box {
  box-shadow: 0 0 20px rgba(255, 255, 255, .8);
}

.node.connect-target:hover .disconnector {
  visibility: hidden;
}

.node.not-connect-target {
  z-index: 5;
}

.node.connect-child {
  z-index: 12;
}

.reference-row {
  z-index: 3;
  position: relative;
}

.reference-cell {
  transition: font-size 70ms linear;
}

.reference-cell .icon {
  color: #cacccc;
  margin: auto;
}

.reference-cell.drop-target {
  background-color: rgba(100, 100, 230, .03);
  border: 2px dashed rgba(100, 100, 230, .55);
  border-radius: 15px;
  transition: background-color .6s cubic-bezier(.24, 1.33, .59, .89);
}

.reference-cell:hover {
  cursor: copy;
  background-color: rgba(100, 100, 230, .03);
  border-radius: 15px;
  transition: background-color .6s cubic-bezier(.24, 1.33, .59, .89);
}

#toolbar {
  z-index: 42;
  font-weight: 800;
}

#node-area-background-cover {
  z-index: 1;
  position: absolute;
}

.summary {
  height: calc(100vh - 80px);
  z-index: 10;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #cacccc;
  border-radius: 0;
  top: 52px;
  right: 1.3vh;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 2px 4px 7px 4px rgba(0, 0, 0, .25);
  position: fixed !important;
}

.summary.can-connect {
  border: none;
}

.summary.can-connect:hover {
  cursor: pointer;
  box-shadow: 2px 4px 7px 4px #47bdb3;
}

.summary.disabled {
  border: none;
}

.form .text-input-description {
  color: #cacccc;
  height: 0;
  margin-top: -13px;
  font-size: 80%;
  font-style: italic;
}

.form .text-input-description.invalid {
  color: #fa5e5c;
}

.radiobutton:hover {
  box-shadow: 0 0 1px 1px #42b8ae;
}

.svg-streams {
  z-index: 4;
  cursor: default;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.line {
  stroke: rgba(0, 0, 0, .1);
}

.connecting-view {
  z-index: 13;
}

.table-row {
  transition: transform .6s cubic-bezier(.24, 1.33, .59, .89);
}

.table-row:not(:first-of-type) .gtin {
  opacity: 0;
  transition: opacity .3s ease-out;
}

.table-row:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.table-row .button, .table-row .climate-footprint-unit {
  opacity: 0;
  transition: opacity .3s ease-out;
}

.table-row:hover {
  transform: translateY(-1px);
}

.table-row:hover .button, .table-row:hover .climate-footprint-unit, .table-row:hover:after, .table-row:hover:not(:first-of-type) .gtin {
  opacity: 1;
}

.switch {
  width: 44px;
  height: 26px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #707070;
  transition: all .2s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slider:before {
  content: "";
  height: 19px;
  width: 19px;
  background-color: #707070;
  border: 2px solid #fff;
  transition: all .2s;
  position: absolute;
  bottom: 1.5px;
  left: 1px;
}

input:checked + .slider {
  background-color: #42b8ae;
  border-color: #42b8ae;
  transition: all .2s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  height: 19px;
  width: 19px;
  background-color: #42b8ae;
  border: 2px solid #fff;
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.radio-control {
  width: 20px;
  height: 20px;
  border: 2px solid #226174;
  border-radius: 50%;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  display: grid;
}

.radio-input {
  display: -ms-flexbox;
  display: flex;
}

.radio-input input {
  display: none;
}

.radio-input input:focus + .radio-control {
  box-shadow: 0 0 4px #226174;
}

input + .radio-control:before {
  content: "";
  width: .5em;
  height: .5em;
  visibility: hidden;
  border-radius: 50%;
  box-shadow: inset .5em .5em #226174;
}

input:checked + .radio-control:before {
  visibility: visible;
}

.radio {
  cursor: pointer;
  grid-template-rows: min-content auto;
  grid-template-columns: min-content auto;
  gap: 0 10px;
  margin: 10px 0;
  display: grid;
}

.radio .radio-label {
  margin: auto 0;
  display: grid;
}

.radio .radio-label-checked {
  font-weight: bold;
}

.radio .custom-content {
  grid-area: 2 / 2;
}

.loading-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid #6dcac3;
  border-top-color: #2f837c;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

#startpage .sticky {
  z-index: 1;
  position: sticky;
  top: 0;
}

#startpage .white-space-nowrap > div {
  white-space: nowrap;
}

.cc-search-input {
  background-color: #fff;
  border-radius: 3px;
}

.cc-search-input input[type="text"] {
  vertical-align: middle;
  height: 35px;
  width: 200px;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  padding: 10px;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 16px;
  display: inline-block;
}

.cc-search-input .cc-search-input-icon {
  vertical-align: middle;
  height: 28px;
  display: inline-block;
}

.cc-search-input:focus-within {
  box-shadow: 0 0 0 2px #42b8ae;
}

.monospaced-characters {
  font-family: B612 Mono !important;
}

@font-face {
  font-family: B612 Mono;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(B612 Mono Regular), local(B612Mono-Regular), url("b612mono.a19b9973.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.loading-rows {
  opacity: 0;
  animation: loading-row-animation 1s linear infinite;
}

@keyframes loading-row-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.text-input-with-suffix:focus-within {
  box-shadow: 0 0 4px #42b8ae;
}

/*# sourceMappingURL=index.99ddd299.css.map */
