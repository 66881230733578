.radio-control {
    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgb(34, 97, 116);
}

.radio-input {
    display: flex;
    input {
        display: none;
        &:focus + .radio-control {
            box-shadow: 0 0 4px rgb(34, 97, 116);
        }
    }
}

input + .radio-control::before {
    content: "";
    width: 0.5em;
    height: 0.5em;
    box-shadow: inset 0.5em 0.5em rgb(34, 97, 116);
    border-radius: 50%;
    visibility: hidden;
}

input:checked + .radio-control::before {
    visibility: visible;
}

.radio {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: min-content auto;
    gap: 0 10px;
    margin: 10px 0;
    cursor: pointer;

    .radio-label {
        display: grid;
        margin: auto 0;
    }

    .radio-label-checked {
        font-weight: bold;
    }

    .custom-content {
        grid-row: 2;
        grid-column: 2;
    }
}
