@use "../abstracts/variables";

// ----------- NODE Styles -----------

// Material design
$node-background: variables.$brand-foreground;

.node-box-background {
  user-select: none;
}

.node-box {
  fill: $node-background;
}

#cover {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 10;
  cursor: no-drop;

  div {
    position: relative;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
  }
}

.disconnected-connector {
  z-index: 100;
}

.connected-connector {
  z-index: 2;
}

.node {
  z-index: 7;

  .disconnector {
    visibility: hidden;
  }

  &:hover {
    .node-box {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    }
    .disconnector {
      visibility: visible;
    }
  }

  &.connect-target {
    z-index: 10;
    .connector {
      visibility: hidden;
    }
  }

  &.connect-target:hover {
    .node-box {
      box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.8);
    }
    .disconnector {
      visibility: hidden;
    }
  }

  &.not-connect-target {
    z-index: 5;
  }

  &.connect-child {
    z-index: 12;
  }
}

.node-content {
  z-index: 2;
}

.node-connection-controller {
  fill: transparent;
  stroke: transparent;
}
